import React, { useState, useEffect } from "react";
import { Button, Space, Drawer, Tooltip, Avatar, message } from "antd";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import {
  DepositIcon,
  MarketIcon,
  OverviewIcon,
  PortfolioIcon,
  StatementIcon,
} from "../../../../Helper/CustomIcons";
import FuCapitalLogo from "../../../../Assets/images/FUCapitallogo.svg";
import USDCIcon from "../../../../Assets/images/USDC.svg";
import USDTIcon from "../../../../Assets/images/USDT.svg";
import AddCircle from "../../../../Assets/images/add-circle-outline.svg";
import { useSelector, useDispatch } from "react-redux";
import StableCoinDrawer from "../ChildDrawers/StableCoinDrawer/StableCoinDrawer";
import "./style.scss";
import WalletDrawer from "../ChildDrawers/WalletDrawer/WalletDrawer";
import { walletLogos } from "../../../../Helper/CustomIcons";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { setSelectedWalletAddress } from "../../../../Store/reducers/global";
import { GetProfile, HandelError } from "../../../../Utils/AxiosCall";

export default function SidebarDrawer({ open, onClose, GetWalletBalance }) {
  const SelectedWalletAddress = useSelector(
    (state) => state?.global?.SelectedWalletAddress
  );
  let location = useLocation();
  const dispatch = useDispatch();
  const { open: openWalletList } = useWeb3Modal();
  const { address, status } = useAccount();
  const { disconnect } = useDisconnect();
  const { connect, connectors } = useConnect();

  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [stableCoinDrawer, setStableCoinDrawer] = useState(false);
  const [walletDrawer, setWalletDrawer] = useState(false);
  const [walletListDetails, setWalletListDetails] = useState();
  const [selectedWalletDetails, setSelectedWalletDetails] = useState();
  const [depositOnHoldModal, setDepositOnHoldModal] = useState(false);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const routelist = [
    ["/user/overview", "Overview", <OverviewIcon />],
    ["/user/portfolio", "Portfolio", <PortfolioIcon />],
    ["/user/market", "Market", <MarketIcon />],
    ["/user/statement", "Statement", <StatementIcon />],
    ["/user/add-and-withdraw", "Add and Withdraw", <DepositIcon />],
  ];

  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const GlobalProfileData = useSelector(
    (state) => state.global.GlobalProfileData
  );

  const onShowStableCoinDrawer = () => {
    setStableCoinDrawer(true);
  };
  const onCloseStableCoinDrawer = () => {
    setStableCoinDrawer(false);
  };
  const onShowWalletDrawer = () => {
    setWalletDrawer(true);
  };
  const onCloseWalletDrawer = () => {
    setWalletDrawer(false);
  };
  const onCloseAll = () => {
    stableCoinDrawer && setStableCoinDrawer(false);
    walletDrawer && setWalletDrawer(false);
    onClose();
  };

  const GetWalletBalanceData = async () => {
    try {
      const response = await GetWalletBalance({
        currencyCode: SelectedStableCoin,
      });
      setWalletListDetails(response);
      if (address) {
        const details = response.walletBalances.find(
          (e) => e.walletAddress === address
        );
        setSelectedWalletDetails(details);
      }
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (address) {
      dispatch(setSelectedWalletAddress(address));
    }
    GetWalletBalanceData();
    const intervalId = setInterval(() => {
      GetWalletBalanceData();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [address, SelectedStableCoin]);

  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }
  function shortenAddress(address) {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  }

  console.log("comparing adresses", address, SelectedWalletAddress);

  return (
    <Drawer
      title={
        <div className="drawer-header">
          <img className="drawer-logo" src={FuCapitalLogo} alt="logo" />
          <CloseOutlined onClick={onClose} style={{ fontSize: "20px" }} />
        </div>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      width="100vw"
    >
      <div className="routing-container">
        {routelist.map((route) => (
          <Link
            to={route[0]}
            className={`route-item ${
              selectedKey === route[0] ? "route-selected" : ""
            }`}
          >
            <span>{route[2]}</span>
            <span>{route[1]}</span>
          </Link>
        ))}
      </div>
      <div className="drawer-footer">
        <div className="button-label-container">
          <span className="label">StableCoin</span>
          <Button
            ghost
            className="drawer-button"
            onClick={onShowStableCoinDrawer}
          >
            <div className="img-text-container">
              <img
                src={SelectedStableCoin === "USDT" ? USDTIcon : USDCIcon}
                alt={SelectedStableCoin}
              />
              <span>{SelectedStableCoin}</span>
            </div>
            <div>
              <RightOutlined style={{ fontSize: "16px" }} />
            </div>
          </Button>
        </div>
        <div className="button-label-container">
          <span className="label">Wallet</span>
          {GlobalProfileData?.investorStatus === "NOT_VERIFIED" ||
          GlobalProfileData?.investorStatus === "REGISTRATION" ? (
            <>
              <Tooltip title="Verification under Progress">
                <div>Connect Wallet</div>
                <img src={AddCircle} alt="add circle" />
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                ghost
                className="drawer-button"
                onClick={onShowWalletDrawer}
              >
                {address ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <>
                      {getWalletLogo(selectedWalletDetails?.walletName) ? (
                        <img
                          src={getWalletLogo(selectedWalletDetails?.walletName)}
                          style={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                          }}
                          alt="wallet logo"
                        />
                      ) : (
                        <MetaMaskAvatar address={address} size={24} />
                      )}
                    </>
                    {shortenAddress(address)}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Avatar
                      className="header-dropdown-avatar"
                      size={24}
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: 16,
                      }}
                    >
                      {walletListDetails?.walletBalances?.length}
                    </Avatar>
                    <span>All linked wallets</span>
                  </div>
                )}
                <RightOutlined style={{ fontSize: "16px" }} />
              </Button>
            </>
          )}
        </div>
        <StableCoinDrawer
          SelectedStableCoin={SelectedStableCoin}
          open={stableCoinDrawer}
          onClose={onCloseStableCoinDrawer}
          onCloseAll={onCloseAll}
        />
        {walletListDetails && (
          <WalletDrawer
            open={walletDrawer}
            onClose={onCloseWalletDrawer}
            onCloseAll={onCloseAll}
            walletListDetails={walletListDetails.walletBalances}
            address={address}
            status={status}
            shortenAddress={shortenAddress}
            getWalletLogo={getWalletLogo}
            setDepositOnHoldModal={setDepositOnHoldModal}
            setSelectedWalletAddress={setSelectedWalletAddress}
            dispatch={dispatch}
          />
        )}
      </div>
    </Drawer>
  );
}
