import React, { useEffect, useState } from "react";
import "./style.scss";
import { GetProfile } from "../../../Utils/AxiosCall";
import { newNumberformat } from "../../../Helper/HelperFunction";
import { useSelector } from "react-redux";
import SummaryDetails from "../OverviewDetails/SummaryDetails/SummaryDetails";
import IncomeDetails from "../OverviewDetails/IncomeDetails/IncomeDetails";
import PortfolioDetails from "../OverviewDetails/PortfolioDetails/PortfolioDetails";
import { message, Spin } from "antd";
import OverviewLinkedWallets from "../OverviewLinkedWallets/OverviewLinkedWallets";
import OverviewHeader from "../OverviewHeader/OverviewHeader";

export default function AccountOverview({
  getAccountOverviewData,
  getWalletBalance,
  deleteWallet,
}) {
  const currencyCode = useSelector((state) => state.global.SelectedStableCoin);
  const [walletBalance, setWalletBalance] = useState([]);
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const GetAccountOverviewData = async (params) => {
    try {
      const response = await getAccountOverviewData(params);
      setData(response);
    } catch (error) {
      message.error(error);
    }
  };

  const GetWalletBalance = async (params) => {
    try {
      const response = await getWalletBalance(params);
      setWalletBalance(response);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    GetProfile();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([
          GetAccountOverviewData({ currencyCode: currencyCode }),
          GetWalletBalance({ currencyCode: currencyCode }),
        ]);
      } catch (error) {
        message.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currencyCode]);

  return (
    <Spin spinning={isLoading} delay={200}>
      <div
        style={{
          backgroundColor: "#F6F6F6",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}>
          <OverviewHeader
            avaliableBalance={newNumberformat(data?.cashBalance)}
          />
          <div></div>
          <div
            className="account-overview-container"
            style={{ paddingTop: "32px" }}
          >
            <div className="account-overview-item">
              <SummaryDetails
                currency={data?.currency}
                currencySymbol={data?.currencySymbol}
                availableFunds={newNumberformat(data?.cashBalance)}
                totalPending={newNumberformat(data?.totalPending)}
                totalInvestments={newNumberformat(data?.principalInvested)}
                totalIncome={newNumberformat(data?.accountValue)}
                currencyCode={currencyCode}
              />
            </div>
            <div className="account-overview-item">
              <IncomeDetails
                currency={data?.currency}
                currencySymbol={data?.currencySymbol}
                secondaryMarketIncome={newNumberformat(
                  data?.secondaryMarketIncome
                )}
                interestReceived={newNumberformat(data?.interestReceived)}
                irr={newNumberformat(data?.internalRateOfReturnPercent)}
                totalIncome={newNumberformat(data?.totalIncome)}
                currencyCode={currencyCode}
              />
            </div>
            <div className="account-overview-item">
              <PortfolioDetails
                currency={data?.currency}
                currencySymbol={data?.currencySymbol}
                principalCurrent={newNumberformat(data?.principalCurrent)}
                totalPrincipalLate={newNumberformat(data?.totalPrincipalLate)}
                principalPending={newNumberformat(data?.principalPending)}
                totalInvestments={newNumberformat(data?.totalInvestments)}
                currencyCode={currencyCode}
                principalInvested={newNumberformat(data?.principalInvested)}
              />
            </div>
          </div>
          {walletBalance?.walletBalances?.length > 0 &&
            walletBalance?.walletBalances[0]?.walletAddress && (
              <div style={{ paddingTop: "32px" }}>
                <div className="card card-style">
                  <OverviewLinkedWallets
                    data={walletBalance?.walletBalances}
                    GetBalances={GetWalletBalance}
                    deleteWallet={deleteWallet}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </Spin>
  );
}
