import { Table } from "antd";
import React, { useState } from "react";
import "./style.scss";
import { newNumberformat } from "../../../Helper/HelperFunction";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { walletLogos } from "../../../Helper/CustomIcons";
import deleteicon from "../../../Assets/images/delete_icon.svg";
import OverviewDeleteModal from "../OverviewDeleteModal/OverviewDeleteModal";

function OverviewLinkedWallets({ data, GetBalances, deleteWallet }) {
  function getWalletLogo(walletID) {
    return walletLogos[walletID] || false;
  }

  const columns1 = [
    {
      title: "Linked wallet",
      dataIndex: "walletAddress",
      key: "walletAddress",
      align: "left",
      width: "60%",
      render: (ele, key) => {
        return (
          <div
            className="account-overview-table-value"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            {getWalletLogo(key?.walletName) ? (
              <img
                src={getWalletLogo(key?.walletName)}
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                }}
                alt="wallet logo"
              />
            ) : (
              <MetaMaskAvatar address={key?.walletAddress} size={24} />
            )}
            <span>{key?.walletAddress} </span>
            {!getWalletLogo(key?.walletName) ? key?.walletName : ""}
          </div>
        );
      },
    },
    {
      title: "Available balance",
      dataIndex: "walletBalance",
      align: "right",
      width: "15%",
      render: (ele, key) => {
        return (
          <div className="account-overview-table-value">
            {newNumberformat(key?.walletBalance)}
          </div>
        );
      },
    },
    {
      title: "Deployed balance",
      dataIndex: "investedAmount",
      align: "right",
      width: "15%",
      render: (ele, key) => {
        return (
          <div className="account-overview-table-value">
            {newNumberformat(key?.investedAmount)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      width: "10%",
      render: (ele, key) => {
        return (
          key.canDelete && (
            <div
              className="account-overview-table-value"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img
                src={deleteicon}
                alt="delete icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(true);
                  setWalletAddressDeleted(key?.walletAddress);
                }}
              />
            </div>
          )
        );
      },
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [walletAddressDeleted, setWalletAddressDeleted] = useState("");
  return (
    <div>
      <div>
        <Table
          columns={columns1}
          dataSource={data || []}
          pagination={false}
          rowKey={(record) => record?.id}
          className="custom-table"
        />
      </div>
      <OverviewDeleteModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        walletAddress={walletAddressDeleted}
        setWalletAddress={setWalletAddressDeleted}
        GetBalances={GetBalances}
        deleteWallet={deleteWallet}
      />
    </div>
  );
}

export default OverviewLinkedWallets;
