// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-link {
  text-decoration: none;
}
.custom-link .img {
  height: 74.34px;
  width: 28px;
  transition: "transform 0.5s ease, width 0.5s ease, height 0.5s ease";
}

.header-container {
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  padding: 0px 20px;
}

.anticon svg {
  color: #fff;
}

.header-options {
  display: flex;
  justify-content: center;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Mobile/Header/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,eAAA;EACA,WAAA;EACA,oEAAA;AAEJ;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AACF","sourcesContent":[".custom-link {\n  text-decoration: none;\n  .img {\n    height: 74.34px;\n    width: 28px;\n    transition: \"transform 0.5s ease, width 0.5s ease, height 0.5s ease\";\n  }\n}\n\n.header-container {\n  background-color: #242424;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  min-height: 56px;\n  padding: 0px 20px;\n}\n\n.anticon svg {\n  color: #fff;\n}\n\n.header-options {\n  display: flex;\n  justify-content: center;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
