import React from "react";

function IncomeDetails({
  currencyCode,
  currencySymbol,
  secondaryMarketIncome,
  interestReceived,
  irr,
  totalIncome,
}) {
  return (
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">
            Total income, {currencyCode}
          </div>
          <div className="heading-account-overview-value">
            {totalIncome.length > 10
              ? totalIncome.slice(0, 10) + "..."
              : totalIncome}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Interest income</div>
              <div>{interestReceived}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Secondary market income</div>
              <div>{secondaryMarketIncome}</div>
            </div>
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="">Total, {currencyCode}</div>
            <div className=" account-overview-body-content-data-bold">
              {totalIncome}
            </div>
          </div>
          <div
            className="account-overview-body-content-data"
            style={{ paddingTop: "12px" }}
          >
            <div className="">Interest, {currencyCode}</div>
            <div className=" account-overview-body-content-data-bold">
              %&nbsp;{irr}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeDetails;
