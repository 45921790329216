import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

function PortfolioDetails({
  currencyCode,
  currencySymbol,
  principalCurrent,
  totalPrincipalLate,
  principalPending,
  totalInvestments,
  principalInvested,
}) {
  return (
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">Number of claims</div>
          <div className="heading-account-overview-value">
            {totalInvestments}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Active</div>
              <div>{principalCurrent}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Pending</div>
              <div>{totalPrincipalLate}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Defaulted</div>
              <div>{principalPending}</div>
            </div>
            {/* <div className='account-overview-body-content-data'>
                    <div>1 - 15 days late</div>
                    <div>{wrappedPrincipalLate15}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>16 - 30 days late</div>
                    <div>{wrappedPrincipalLate30}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>31 - 60 days late</div>
                    <div>{wrappedPrincipalLate60}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>61 + days late</div>
                    <div>{wrappedPrincipalLate61plus}</div>
                    </div> */}
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="">Total, {currencyCode}</div>
            <div className=" account-overview-body-content-data-bold">
              {principalInvested}
            </div>
          </div>
          <div style={{ marginTop: "32px" }}>
            <Link to="/user/market" style={{ textDecoration: "none" }}>
              <Button
                className="new_button_filled"
                style={{ width: "100%", height: "48px" }}
              >
                Buy
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioDetails;
