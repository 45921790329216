import api from "./api";

const fetchAccountOverviewData = async (url, params) => {
  try {
    const response = await api.post(url, params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccountData = (params) =>
  fetchAccountOverviewData("/api/investor/account-summary", params);

export const getWalletBalance = (params) =>
  fetchAccountOverviewData("/api/investor/wallet-balance", params);

export const deleteWallet = (params) =>
  fetchAccountOverviewData("/api/investor/delete-wallet", params);
