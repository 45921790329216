import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, Outlet } from "react-router-dom";
import FuCapitalLogo from "../../../../Assets/images/FUCapitallogo.svg";
import { MenuOutlined } from "@ant-design/icons";
import SidebarDrawer from "../SidebarDrawer/SidebarDrawer";
import { getWalletBalance } from "../../../../services/walletService";
import { message } from "antd";
import { GetProfile } from "../../../../Utils/AxiosCall";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import NotificationsDropdown from "../NotificationsDropdown/NotificationsDropdown";

export default function MobileHeader() {
  const [showDrawer, setShowDrawer] = useState(false);
  const onShow = () => {
    setShowDrawer(true);
  };
  const onClose = () => {
    setShowDrawer(false);
  };

  const GetWalletBalance = async (params) => {
    try {
      const response = await getWalletBalance(params);
      if (response.status === 200) {
        return response.data;
      } else {
        message.error(response.status);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);
  return (
    <>
      <div className="header-container">
        <Link to={"/user/overview"} className="custom-link">
          <img src={FuCapitalLogo} alt="logo" />
        </Link>
        <div className="header-options">
          <NotificationsDropdown />
          <ProfileDropdown />
          <MenuOutlined onClick={onShow} style={{ fontSize: "20px" }} />
        </div>

        <SidebarDrawer
          onClose={onClose}
          open={showDrawer}
          GetWalletBalance={GetWalletBalance}
        />
      </div>
      <Outlet />
    </>
  );
}
