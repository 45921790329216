// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stable-coin-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stable-coin-drawer-header .left-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.stable-coin-selector-container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
.stable-coin-selector-container .currency-container {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 20px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.6509803922);
  border-left: 4px solid transparent;
}
.stable-coin-selector-container .currency-container .currency-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.stable-coin-selector-container .currency-container:hover {
  background-color: rgba(22, 22, 22, 0.6980392157) !important;
  color: #fff !important;
}

.selected {
  border-left: 4px solid #ec441f !important;
  color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Mobile/ChildDrawers/StableCoinDrawer/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wCAAA;EACA,kCAAA;AAEJ;AADI;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,QAAA;AAGN;AADI;EACE,2DAAA;EACA,sBAAA;AAGN;;AAEA;EACE,yCAAA;EACA,sBAAA;AACF","sourcesContent":[".stable-coin-drawer-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .left-header {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 12px;\n  }\n}\n\n.stable-coin-selector-container {\n  margin-top: 12px;\n  display: flex;\n  flex-direction: column;\n  .currency-container {\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: left;\n    padding: 20px;\n    height: 56px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: #ffffffa6;\n    border-left: 4px solid transparent;\n    .currency-item {\n      display: flex;\n      justify-content: flex-start;\n      align-items: center;\n      gap: 8px;\n    }\n    &:hover {\n      background-color: #161616b2 !important;\n      color: #fff !important;\n    }\n  }\n}\n\n.selected {\n  border-left: 4px solid #ec441f !important;\n  color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
