import React from "react";
import { Button, Drawer, message, Spin } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { newNumberformat } from "../../../../../Helper/HelperFunction";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useConnect, useDisconnect } from "wagmi";
import { GetProfile, HandelError } from "../../../../../Utils/AxiosCall";
import CheckIcon from "../../../../../Assets/images/check.svg";
import "./style.scss";

function WalletDrawer({
  open,
  onClose,
  onCloseAll,
  walletListDetails,
  address,
  shortenAddress,
  getWalletLogo,
  setDepositOnHoldModal,
  status,
  setSelectedWalletAddress,
  dispatch,
}) {
  const { open: openWalletList } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();
  const { connectors, connectAsync } = useConnect();

  const disconnectWallets = async () => {
    await disconnectAsync();
    dispatch(setSelectedWalletAddress(""));
    localStorage.removeItem("walletAddress");
  };

  const connectWallet = async () => {
    onCloseAll();
    try {
      const response = await GetProfile();
      if (response) {
        if (response.holdDeposits) {
          return setDepositOnHoldModal(true);
        }
        disconnectWallets();

        try {
          openWalletList({ view: "AllWallets" });
          localStorage.setItem("walletAddress", address);
        } catch (error) {
          message.error(error);
        }

        return response;
      }
    } catch (error) {
      HandelError(error);
    }
  };

  const findConnectorById = (connectors, id) => {
    return connectors.find((connector) => connector.name === id) || null;
  };
  const handleConnect = async (walletName) => {
    const connector = findConnectorById(connectors, walletName);

    if (connector) {
      connectAsync({ connector: connector });
    } else {
      message.error("Wallet not found");
    }
  };

  const handleWalletChange = async ({ walletAddress, walletName }) => {
    try {
      if (status !== "disconnected") {
        await disconnectAsync();
        dispatch(setSelectedWalletAddress(""));
      }

      await handleConnect(walletName);
    } catch (error) {
      message.error(error);
    }
  };
  return (
    <Drawer
      title={
        <div className="stable-coin-drawer-header">
          <span className="left-header">
            <ArrowLeftOutlined style={{ fontSize: "20px" }} onClick={onClose} />
            Wallet
          </span>
          <CloseOutlined style={{ fontSize: "20px" }} onClick={onCloseAll} />
        </div>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      width="100vw"
    >
      {console.log("status", status)}
      <Spin spinning={status === "connecting"} delay={200}>
        <div className="drawer-body-container">
          <div className="wallet-list-container">
            {walletListDetails.map((wallet) => (
              <Button
                ghost
                className={`btn-wallet-details ${
                  wallet.walletAddress === address && "selected"
                } `}
                key={wallet.walletId}
                disabled={wallet.walletAddress === address}
                onClick={() =>
                  handleWalletChange({
                    walletAddress: wallet.walletAddress,
                    walletName: wallet.walletName,
                  })
                }
              >
                <div className="wallet-info">
                  <>
                    {getWalletLogo(wallet.walletName) ? (
                      <img
                        src={getWalletLogo(wallet.walletName)}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        alt="wallet logo"
                      />
                    ) : (
                      <MetaMaskAvatar
                        address={wallet.walletAddress}
                        size={24}
                      />
                    )}
                  </>
                  {shortenAddress(wallet.walletAddress)}
                  <span style={{ fontSize: "12px" }}>{`${
                    !getWalletLogo(wallet.walletName)
                      ? "(" + wallet.walletName + ")"
                      : ""
                  }`}</span>
                </div>
                <div className="amount-tick-container">
                  <span>{newNumberformat(wallet.walletBalance)}</span>
                  {wallet.walletAddress === address && (
                    <img src={CheckIcon} alt="check-icon" />
                  )}
                </div>
              </Button>
            ))}
          </div>
          <div className="connect-new-wallet">
            <Button onClick={connectWallet}>Connect new wallet</Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default WalletDrawer;
