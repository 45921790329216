import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./Store/store";
import { ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga4";
import AuthContextProvider from "./Contexts/AuthContext";
import { Web3ModalProvider } from "./Utils/web3ModalConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize([{ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID }]);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AuthContextProvider>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Inter",
                colorPrimary: "#DE3712",
                colorError: "#D02342",
              },
              components: {
                Table: {
                  bodySortBg: "#fff",
                  headerSortActiveBg: "#fff",
                },
                Button: {
                  defaultBg: "#ec441f",
                  defaultColor: "#fff",
                  defaultHoverBg: "rgba(236, 68, 31, 0.85)",
                  defaultActiveBg: "rgba(236, 68, 31, 0.70)",
                  defaultActiveColor: "#fff",
                  defaultHoverColor: "#fff",
                },
              },
            }}
          >
            <Web3ModalProvider>
              <App />
            </Web3ModalProvider>
          </ConfigProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
