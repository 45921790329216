import React from "react";
import "./style.scss";

function SummaryDetails({
  currencyCode,
  availableFunds,
  totalPending,
  totalInvestments,
  totalIncome,
}) {
  return (
    // custom-padding-bottom-12
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">
            Summary, {currencyCode}
          </div>
          <div className="heading-account-overview-value">
            {totalIncome.length > 10
              ? totalIncome.slice(0, 10) + "..."
              : totalIncome}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Available balance</div>
              <div>{availableFunds}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Deployed balance</div>
              <div>{totalInvestments}</div>
            </div>
            {/* <div className='account-overview-body-content-data'>
                            <div>Pending Payments</div>
                            <div>{wrappedTotalPending||'0'}</div>
                        </div> */}
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="account-overview-body-content-data">
              Total,{currencyCode}
            </div>
            <div className=" account-overview-body-content-data-bold">
              {totalIncome}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryDetails;
