import { Button } from "antd";
import React, { useState } from "react";
import "./style.scss";
import { message } from "antd";
import { Modal } from "antd";
import { useAccount, useDisconnect } from "wagmi";

function OverviewDeleteModal({
  isOpen,
  setIsOpen,
  walletAddress,
  setWalletAddress,
  GetBalances,
  deleteWallet,
}) {
  const handleClose = () => {
    setWalletAddress("");
    setIsOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await deleteWallet({ walletAddress: walletAddress });
      if (address === walletAddress) {
        disconnect();
      }
      handleClose();
      setIsLoading(false);
      setIsOpen(false);
      GetBalances();
      message.success("Wallet address deleted successfully");
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to delete wallet address");
    }
  };
  return (
    <Modal open={isOpen} centered footer={null} onCancel={() => handleClose()}>
      <div className="container" style={{ padding: "24px" }}>
        <center>
          <h2 className="eb_garamond_font" style={{ marginBottom: "24px" }}>
            Delete Wallet
          </h2>
        </center>
        <div
          className="custom-padding-top-24"
          style={{
            lineHeight: "20px",
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "24px",
          }}
        >
          Are you sure you want to delete this wallet address from the FU
          Capital account?
        </div>
        <div className="row">
          <div className="col-6">
            <Button
              className="new_button_filled hight"
              style={{ width: "100%" }}
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
          </div>
          <div className="col-6">
            <Button
              className="new_button_outlined hight"
              style={{ width: "100%" }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OverviewDeleteModal;
