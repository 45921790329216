import React from "react";
import { Button, Drawer } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import {
  setCurrencyCode,
  setSelectedStableCoin,
} from "../../../../../Store/reducers/global";
import CheckIcon from "../../../../../Assets/images/check.svg";
import USDCIcon from "../../../../../Assets/images/USDC.svg";
import USDTIcon from "../../../../../Assets/images/USDT.svg";
import { useDispatch } from "react-redux";
import "./style.scss";

function StableCoinDrawer({ open, onClose, onCloseAll, SelectedStableCoin }) {
  const dispatch = useDispatch();
  const setStableCoin = (coin) => {
    dispatch(setSelectedStableCoin(coin));
    dispatch(setCurrencyCode(coin));
  };
  const currencyList = [
    ["USDT", USDTIcon],
    ["USDC", USDCIcon],
  ];
  return (
    <Drawer
      title={
        <div className="stable-coin-drawer-header">
          <span className="left-header">
            <ArrowLeftOutlined style={{ fontSize: "20px" }} onClick={onClose} />
            StableCoin
          </span>
          <CloseOutlined style={{ fontSize: "20px" }} onClick={onCloseAll} />
        </div>
      }
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      width="100vw"
    >
      <div className="stable-coin-selector-container">
        {currencyList.map((currency) => (
          <div
            className={`currency-container ${
              SelectedStableCoin === currency[0] ? "selected" : ""
            }`}
            onClick={() => setStableCoin(currency[0])}
          >
            <div className="currency-item">
              <img src={currency[1]} alt="usdt" />
              <span>{currency[0]}</span>
            </div>
            {SelectedStableCoin === currency[0] && (
              <img src={CheckIcon} alt="check" />
            )}
          </div>
        ))}
      </div>
    </Drawer>
  );
}

export default StableCoinDrawer;
