import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import NoLayout from "./Layouts/NoLayout";
import NoPage from "./Pages/NoPage/NoPage";
import InvestorLeftNavbar from "./Layouts/InvestorLeftNavbar";
import InvestorMarketPage from "./Pages/InvestorMarketPage/InvestorMarketPage";
import PageTopLoader from "./Components/PageTopLoader/PageTopLoader";
import ProfileSettingsPage from "./Pages/ProfileSettingsPage/ProfileSettingsPage";
import InvestorLoginPage from "./Pages/InvestorLoginPage/InvestorLoginPage";
import InvestorSignUpPage from "./Pages/InvestorSignUpPage/InvestorSignUpPage";
import "./custom-theme.less";
import InvestorPersonalData from "./Pages/InvestorSignUpPage/InvestorPersonalData";
import InvestorFinancialData from "./Pages/InvestorSignUpPage/InvestorFinancialData";
import DepositAndWithdrawFunds from "./Pages/DepositAndWithdrawFunds/DepositAndWithdrawFunds";
import AccountOverview from "./Pages/AccountOverview/AccountOverview";
// import AccountStatement from "./Pages/AccountStatement/AccountStatement";
import AccountStatement from "./Components/StatementPageComponents/AccountStatement/AccountStatement";
// import MyPortfolio from "./Pages/MyPortfolio/MyPortfolio";
import MyPortfolioOptimized from "./Components/PortfolioOptimized/MyPortfolio";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import LoanDetails from "./Pages/LoanDetails/LoanDetails";
import BulkInvestPage from "./Pages/BulkInvest/BulkInvestPage";
import { Web3ModalProvider } from "./Utils/web3ModalConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCurrencyCode } from "./Store/reducers/global";
import SignUp from "./Pages/NewAccountCreationFlow/SignUp";
import VerifyEmail from "./Pages/NewAccountCreationFlow/VerifyEmail";
import PersonalData from "./Pages/NewAccountCreationFlow/PersonalData";
import FinancialData from "./Pages/NewAccountCreationFlow/FinancialData";
import VeriffVerification from "./Pages/NewAccountCreationFlow/VeriffVerification";
import VerificationUnderProcess from "./Pages/NewAccountCreationFlow/VerificationUnderProcess";
import GuestLayout from "./Layouts/GuestLayout";
import TestPage from "./Pages/GuestPages/TestPage";
import GuestMarketPage from "./Pages/GuestPages/GuestMarketPage";
import GuestPageLoanDetails from "./Pages/GuestPages/GuestPageLoanDetails";
import UnderMaintenance from "./Pages/UnderMaintenance/UnderMaintenance";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AccountStatementPage from "./Routes/AccountStatementPage";
import AccountOverviewPage from "./Routes/AccountOverviewPage";
import { useScreenSize } from "./Hooks/useScreenSize";
import MobileHeader from "./Components/Header/Mobile/Header/Header";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);
};

function App() {
  const dispatch = useDispatch();
  dispatch(setCurrencyCode("USDT"));
  const screenSize = useScreenSize();
  const token = useSelector((state) => state.global.LoginToken);
  const investorAuth = localStorage.getItem("token");

  function InvestorPrivateRoute() {
    return investorAuth ? (
      // <Web3ModalProvider>
      <Outlet />
    ) : (
      // </Web3ModalProvider>
      <Navigate to="/user/guest-market-page" />
    );
    // <></>
  }

  // if (process.env.REACT_APP_ENV === "PROD") {
  //   console.info = function () {};
  //   console.warn = function () {};
  //   console.error = function () {};
  //   console.log = function () {};
  // }
  usePageTracking();

  return (
    <>
      <Routes>
        <Route element={<Navigate to="/user/overview" replace />} path="/" />

        {/* INVESTOR PRIVATE ROUTE  */}

        <Route element={<InvestorPrivateRoute />}>
          {screenSize.width <= 768 ? (
            <Route element={<MobileHeader />}>
              <Route element={<InvestorMarketPage />} path="/user/market" />
              <Route
                element={<ProfileSettingsPage />}
                path="/user/profile-settings"
              />
              <Route
                element={<DepositAndWithdrawFunds />}
                path="/user/add-and-withdraw"
              />

              <Route element={<AccountOverviewPage />} path="/user/overview" />
              <Route
                element={<AccountStatementPage />}
                path="/user/statement"
              />
              <Route
                element={<MyPortfolioOptimized />}
                path="/user/portfolio"
              />
              <Route element={<LoanDetails />} path="/user/loan/:id" />
              <Route element={<BulkInvestPage />} path="/user/bulk-invest" />
            </Route>
          ) : (
            <Route element={<InvestorLeftNavbar />}>
              <Route element={<InvestorMarketPage />} path="/user/market" />
              <Route
                element={<ProfileSettingsPage />}
                path="/user/profile-settings"
              />
              <Route
                element={<DepositAndWithdrawFunds />}
                path="/user/add-and-withdraw"
              />
              {/* <Route element={<AccountOverview />} path="/user/overview" /> */}
              <Route element={<AccountOverviewPage />} path="/user/overview" />
              <Route
                element={<AccountStatementPage />}
                path="/user/statement"
              />
              <Route
                element={<MyPortfolioOptimized />}
                path="/user/portfolio"
              />
              <Route element={<LoanDetails />} path="/user/loan/:id" />
              <Route element={<BulkInvestPage />} path="/user/bulk-invest" />
            </Route>
          )}

          <Route element={<NoPage />} path="*" />
        </Route>

        {/* NO LAYOUT ROUTES */}
        <Route element={<NoLayout />}>
          <Route
            element={
              investorAuth ? <Navigate to="/" replace /> : <InvestorLoginPage />
            }
            path="/user/login"
          />
          <Route
            element={investorAuth ? <Navigate to="/" replace /> : <SignUp />}
            path="/user/signUp"
          />
          <Route
            element={
              investorAuth ? <Navigate to="/" replace /> : <PersonalData />
            }
            path="/user/personal-data"
          />
          <Route
            element={
              investorAuth ? <Navigate to="/" replace /> : <FinancialData />
            }
            path="/user/financial-data"
          />
          <Route
            element={
              investorAuth ? <Navigate to="/" replace /> : <VerifyEmail />
            }
            path="/user/verify-email"
          />
          <Route
            element={
              investorAuth ? (
                <Navigate to="/" replace />
              ) : (
                <VeriffVerification />
              )
            }
            path="/user/veriff"
          />
          <Route
            element={
              investorAuth ? (
                <Navigate to="/" replace />
              ) : (
                <VerificationUnderProcess />
              )
            }
            path="/user/veriff-landing"
          />

          <Route element={<ForgotPassword />} path="/user/forgot-password" />
          <Route
            element={<ResetPassword />}
            path="/user/reset-password/:token"
          />
          <Route element={<NoPage />} path="*" />
          <Route element={<UnderMaintenance />} path="/under-maintenance" />
        </Route>

        <Route element={<GuestLayout />}>
          <Route
            element={
              investorAuth ? <Navigate to="/" replace /> : <GuestMarketPage />
            }
            path="/user/guest-market-page"
            // element={< GuestMarketPage/>}
          />
          <Route
            element={
              investorAuth ? (
                <Navigate to="/" replace />
              ) : (
                <GuestPageLoanDetails />
              )
            }
            path="/user/guest-loan-details/:id"
          />
          <Route element={<NoPage />} path="*" />
        </Route>
        <Route element={<NoPage />} path="*" />
      </Routes>
      {/* COMMON COMPONENTS */}
      <PageTopLoader />
    </>
  );
}

export default App;
