import axios from "axios";
import { message } from "antd";
import { LogOutCurrentSession } from "./Logout";
import { store } from "../Store/store";
import { setGlobeProfileData } from "../Store/reducers/global";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// const clientApi = axios.create({
//-------------------------new java api-------------------------//
// console.log(process.env.REACT_APP_BASE_URL)
export const clientApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    // authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export const GetProfile = async () => {
  try {
    const response = await clientApi.get("/api/investor/profile");
    if (response?.status === 200) {
      store.dispatch(setGlobeProfileData(response?.data));
      localStorage.setItem("investorId", response?.data?.userId);
      localStorage.setItem("investorFirstName", response?.data?.firstName);
      localStorage.setItem("investorLastName", response?.data?.lastName);
      localStorage.setItem(
        "2FAStatus",
        response?.data?.twoFactorAuthenticationEnabled
      );
      localStorage.setItem("investorStatus", response?.data?.investorStatus);
      localStorage.setItem("investorEmail", response?.data?.email);
      return response?.data;
    }
  } catch (error) {
    HandelError(error);
  }
};
export const GetPing = async () => {
  try {
    const response = await clientApi.get("/api/investor/ping");
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      message.error("Session expired. Please login again.");
      setTimeout(() => {
        LogOutCurrentSession();
      }, 2000);
    }
  }
};
export const GetWalletBalance = async () => {
  const state = store.getState();
  const SelectedStableCoin = state.global.SelectedStableCoin;
  try {
    const response = await clientApi.post("/api/investor/wallet-balance", {
      currencyCode: SelectedStableCoin,
    });
    if (response?.status === 200) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    HandelError(error);
  }
};
let logoutInProgress = false;
let errorToastShown = false;
let redirectInProgress = false;
export const HandelError = (error) => {
  // const navigate = useNavigate();
  if (errorToastShown) {
    return;
  }

  if (
    (error?.response?.status === 403 || error?.response?.status === 401) &&
    !logoutInProgress
  ) {
    logoutInProgress = true;
    message.error("Session expired. Please login again.");
    errorToastShown = true;
    setTimeout(() => {
      LogOutCurrentSession();
      logoutInProgress = false;
      errorToastShown = false;
    }, 2000);
  } else {
    // Ensure this block only runs once
    if (!errorToastShown && redirectInProgress === false) {
      // message.error("Ops! Something went wrong.");
      window.location.assign("/under-maintenance");
      console.log("1");
      // navigate('');
      errorToastShown = true;
      redirectInProgress = true;
      setTimeout(() => {
        errorToastShown = false;
        redirectInProgress = false;
      }, 5000);
    }
  }
};
export const gitBookUrl = "https://docs.fucapital.io";
export const versionNo = "1.0.6";
//-------------------------new java api-------------------------//
