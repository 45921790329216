import React, { useEffect, useState } from "react";
import "./style.scss";
import { message, Spin } from "antd";
import { GetProfile } from "../../../Utils/AxiosCall";
import dayjs from "dayjs";
import { newNumberformat } from "../../../Helper/HelperFunction";
import StatementIcon from "../../../Assets/images/receipt-long.svg";
import HeaderLabelTwo from "../../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import PageHeader from "../../../Components/Components/Molecule/PageHeader/PageHeader";
import { useSelector } from "react-redux";
import StatementFilter from "../StatementFilter/StatementFilter";
import StatementSummary from "../StatementSummary/StatementSummary";
import StatementExportToExcel from "../StatementExportToExcel/StatementExportToExcel";
import TransactionsTable from "../TransactionsTable/TransactionsTable";

export default function AccountStatement({
  getStatementData,
  getStatementPDFData,
  getStatementExcelData,
}) {
  const currencyCode = useSelector((state) => state.global.SelectedStableCoin);

  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const initialFilter = {
    currencyCode: currencyCode,
    dateFrom: "",
    dateTo: dayjs().format("YYYY-MM-DD"),
  };
  const [filter, setFilter] = useState(initialFilter);
  const [data, setData] = useState("");
  const [activeChip, setActiveChip] = useState("All Time");
  const [firstTransactionDate, setFirstTransactionDate] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetProfile();
  }, []);

  useEffect(() => {
    let updatedFilter;

    if (currencyCode !== filter.currencyCode) {
      updatedFilter = { ...initialFilter, currencyCode: currencyCode };
      setActiveChip("All Time");
      setFirstTransactionDate(null);
    } else {
      updatedFilter = {
        ...filter,
        currencyCode: currencyCode,
      };
    }
    setFilter(updatedFilter);
    GetAccountStatementData({
      filter: updatedFilter,
      page: page,
      pageSize: pageSize,
    });
  }, [page, pageSize, currencyCode]);

  const ButtonClick = () => {
    if (!filter.dateFrom || !filter.dateTo) {
      message.error("Please select date range");
      return;
    }
    setActiveChip("");
    GetAccountStatementData({
      filter: filter,
      page: page,
      pageSize: pageSize,
    });
  };

  const GetAccountStatementData = async ({ filter, page, pageSize }) => {
    setLoader(true);
    try {
      const response = await getStatementData({
        filter: filter,
        page: page,
        pageSize: pageSize,
      });

      if (filter.dateFrom === "") {
        setFilter({
          ...filter,
          dateFrom: response.lastRecordDate
            ? dayjs(response.lastRecordDate).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
        });
        setFirstTransactionDate(
          response.lastRecordDate
            ? dayjs(response.lastRecordDate).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD")
        );
      }
      setData(response);
    } catch (error) {
      message.error(error);
    } finally {
      setLoader(false);
    }
  };

  const SelectDateFilter = async (dateRange) => {
    if (dateRange === activeChip) return;
    const ranges = {
      Today: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      "Last 7 Days": [
        dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      "This Month": [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().endOf("month").format("YYYY-MM-DD"),
      ],
      "Previous Month": [
        dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ],
      "Previous Year": [
        dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
        dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      ],
      "All Time": [
        dayjs(firstTransactionDate).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
    };

    const [dateFrom, dateTo] = ranges[dateRange];
    setActiveChip(dateRange);
    const updatedFilter = { ...filter, dateFrom: dateFrom, dateTo: dateTo };
    setFilter(updatedFilter);
    GetAccountStatementData({
      filter: updatedFilter,
      page: page,
      pageSize: pageSize,
    });
  };
  const exportData = async (type) => {
    setLoader(true);
    const pageSizes = data.total;

    try {
      let responseData;
      if (type === "pdf") {
        responseData = await getStatementPDFData({
          filter,
          page: 1,
          pageSizes: pageSizes,
        });
      } else if (type === "excel") {
        responseData = await getStatementExcelData({
          filter,
          page: 1,
          pageSizes: pageSizes,
        });
      }

      const { data, headers } = responseData;
      const blob = new Blob([data], { type: headers["content-type"] });
      const filename = headers["x-filename"];

      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      message.error(error.message || "Download failed");
    } finally {
      setLoader(false);
    }
  };

  const SubComponent = ({ data }) => {
    return (
      <>
        <HeaderLabelTwo
          HeaderLabel="Date from"
          data={
            filter.dateFrom
              ? dayjs(filter.dateFrom).format("YYYY-MM-DD")
              : dayjs().format("YYYY-MM-DD")
          }
        />
        <HeaderLabelTwo
          HeaderLabel="Date to"
          data={dayjs(filter?.dateTo).format("YYYY-MM-DD")}
        />
        <HeaderLabelTwo
          HeaderLabel="Opening balance"
          data={newNumberformat(data?.openingBalance) || 0}
          defaultValue="0"
        />
        <HeaderLabelTwo
          HeaderLabel="Closing balance"
          data={newNumberformat(data?.closingBalance) || 0}
          defaultValue="0"
        />
      </>
    );
  };

  return (
    <Spin spinning={loader} delay={200}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PageHeader
          title="Account Statement"
          image={StatementIcon}
          subComponent={<SubComponent data={data} />}
        />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div className="page-width-control">
            <div
              className="custom-hr"
              style={{ paddingTop: "32px", paddingBottom: "16px" }}
            >
              <hr className=" hr-style-2 "></hr>
            </div>
            <div className="statement-first-container">
              <StatementFilter
                filter={filter}
                setFilter={setFilter}
                ButtonClick={ButtonClick}
                loader={loader}
                SelectDateFilter={SelectDateFilter}
                activeChip={activeChip}
              />

              <StatementSummary data={data} exportData={exportData} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <StatementExportToExcel count={data?.total} exportData={exportData} />
        <TransactionsTable
          data={data?.items}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalItems={data?.total}
          setPage={setPage}
          page={page}
          loader={loader}
        />
      </div>
    </Spin>
  );
}
