import React from "react";
import overviewIcon from "../../../Assets/images/account-overview-icon.svg";
import invest_icon from "../../../Assets/images/trending-up-invest.svg";
import depositIcon from "../../../Assets/images/arrow-warm-up.svg";
import statementIcon from "../../../Assets/images/calendar_clock.svg";
import { HeaderButton } from "../../../Components/Components/Atom/HeaderButton/HeaderButton";

function OverviewHeader({ avaliableBalance }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div
          className="eb_garamond_font"
          style={{
            padding: "40px 0px 24px 0px",
            fontSize: "40px",
            lineHeight: "44px",
            fontWeight: "400",
            color: "#242424",
          }}
        >
          Overview
        </div>
        <div
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "row",
            gap: "24px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="header-label">Available balance</div>
            <div className="header-label-value">{avaliableBalance}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div>
              <HeaderButton
                link="/user/market"
                svgPath={<img src={invest_icon} alt="Invest icon" />}
                label={"Buy"}
              />
            </div>
            <div>
              <HeaderButton
                link="/user/add-and-withdraw"
                svgPath={<img src={depositIcon} alt="deposit icon" />}
                label={"Add funds"}
              />
            </div>
            <div>
              <HeaderButton
                link="/user/statement"
                svgPath={<img src={statementIcon} alt="statement icon" />}
                label={"Weekly statement"}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={overviewIcon} alt="overview Icon" />
      </div>
    </div>
  );
}

export default OverviewHeader;
