import React, { createContext, useContext, useState } from "react";
import {
  login as loginService,
  logout as logoutService,
} from "../services/authService";

const AuthContext = createContext(null);
export const useAuthUser = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  const [authError, setAuthError] = useState(null);

  const login = async (credentials) => {
    try {
      const response = await loginService(credentials);
      setIsAuthenticated(true);

      //token creation and storing it in local storage
      const token = "token" + Math.floor(Math.random() * 1000000);
      localStorage.setItem("token", token);

      //code duplication - local storage (remove later)
      localStorage.setItem("investorId", response.investorNumber);
      localStorage.setItem("investorFirstName", response.firstName);
      localStorage.setItem(
        "2FAStatus",
        response.twoFactorAuthenticationEnabled
      );
      localStorage.setItem("investorStatus", response.investorStatus);
      localStorage.setItem("investorEmail", response.email);

      return response;
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const logout = async ({ investorId, investorEmail }) => {
    try {
      const res = await logoutService({ investorId, investorEmail });
    } catch (error) {
      setAuthError(error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, authError }}>
      {children}
    </AuthContext.Provider>
  );
}
