import React from "react";
import AccountOverview from "../Components/AccountOverviewPageComponents/AccountOverview/AccountOverview";
import AccountOverviewMobile from "../Components/AccountOverviewPageComponents/Mobile/AccountOverview/AccountOverview";
import {
  getAccountData,
  getWalletBalance,
  deleteWallet,
} from "../services/accountOverviewService";
import { message } from "antd";
import { useScreenSize } from "../Hooks/useScreenSize";

function AccountOverviewPage() {
  const screenSize = useScreenSize();
  const fetchData = async (fetchFunction, params) => {
    try {
      const response = await fetchFunction(params);

      if (response.status === 200) {
        return response.data;
      } else {
        message.error(response.status);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <>
      {screenSize.width <= 768 ? (
        <AccountOverviewMobile />
      ) : (
        <AccountOverview
          getAccountOverviewData={(params) => fetchData(getAccountData, params)}
          getWalletBalance={(params) => fetchData(getWalletBalance, params)}
          deleteWallet={(params) => fetchData(deleteWallet, params)}
        />
      )}
    </>
  );
}

export default AccountOverviewPage;
