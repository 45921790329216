import api from "./api";

export const login = async (credentials) => {
  try {
    const response = await api.post("/api/investor/public/login", credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async ({ investorId, investorEmail }) => {
  try {
    const response = await api.post(
      `/api/investor/public/logout?userId=${investorId}&username=${investorEmail}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
