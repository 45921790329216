import api from "./api";

export const getWalletBalance = async (params) => {
  try {
    const response = await api.post("/api/investor/wallet-balance", params);
    return response;
  } catch (error) {
    throw error;
  }
};
