import React from "react";
import { Avatar, Dropdown, Space } from "antd";
import "./style.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import badge from "../../../../Assets/images/EarlyBird.png";
import { LogOutCurrentSession } from "../../../../Utils/Logout";
import { useDisconnect } from "wagmi";

function ProfileDropdown() {
  const { firstName, lastName, userId } = useSelector(
    (state) => state.global.GlobalProfileData
  );
  const combinedInitials = firstName?.charAt(0) + lastName?.charAt(0);
  const fullName =
    (firstName ? firstName : "") + " " + (lastName ? lastName : "");
  const truncatedName =
    fullName?.length > 25 ? fullName.slice(0, 21) + "..." : fullName;
  const { disconnectAsync } = useDisconnect();

  const items = [
    {
      label: (
        <div className="header-dropdown-menu-top">
          <div className="header-dropdown-menu-top-inner">
            <div className="header-dropdown-menu-avatar-wrapper">
              <Avatar className="header-dropdowns-avatar" size={64}>
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                >
                  {combinedInitials}
                </span>
              </Avatar>
              <div className="badge-wrapper">
                <img src={badge} alt="Badge" className="badge-image" />
                <span
                  className="badge-text"
                  style={{
                    color: "#C1A783",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  EARLY BIRD BADGE
                </span>
              </div>
            </div>

            <div className="header-dropdown-menu-info">
              <div className="header-dropdown-menu-name">{truncatedName}</div>
              <div className="header-dropdown-menu-id">
                User ID <span style={{ color: "#F6F6F6" }}>{userId}</span>
              </div>
            </div>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="header-dropdown-menu-bottom">
          <div style={{ height: "8px" }}></div>
          <Link
            to={"/user/profile-settings"}
            style={{ textDecoration: "none" }}
            // onClick={() => closeDropdown()}
          >
            <div className="header-dropdown-menu-item">Profile</div>
          </Link>
          <div
            className="header-dropdown-menu-item"
            onClick={async () => {
              await LogOutCurrentSession();
              //   closeDropdown();
              await disconnectAsync();
            }}
          >
            Log out
          </div>
        </div>
      ),
      key: "1",
    },
  ];
  return (
    <Dropdown
      overlayClassName="dropdown-wrapper"
      menu={{
        items,
      }}
      trigger={["click"]}
    >
      <div className="avatar-wrapper" onClick={(e) => e.preventDefault()}>
        <img src={badge} alt="Background" className="background-image" />
        <div className="header-dropdown-avatars">{combinedInitials}</div>
      </div>
    </Dropdown>
  );
}

export default ProfileDropdown;
